/* eslint-disable max-lines */
import { LinkVomitPossibleValue } from '@bbx/search-journey/sub-domains/search/components/common/common/PossibleValueLinkVomit/PossibleValueLinkVomit'

export const carMakes: LinkVomitPossibleValue[] = [
    {
        label: 'Abarth',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/abarth-gebrauchtwagen/',
        },
    },
    {
        label: 'Aixam',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/aixam-gebrauchtwagen/',
        },
    },
    {
        label: 'Alfa Romeo',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/alfa-romeo-gebrauchtwagen/',
        },
    },
    {
        label: 'Alpine',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/alpine-gebrauchtwagen/',
        },
    },
    {
        label: 'Aston Martin',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/aston-martin-gebrauchtwagen/',
        },
    },
    {
        label: 'Audi',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/audi-gebrauchtwagen/',
        },
    },
    {
        label: 'Austin',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/austin-gebrauchtwagen/',
        },
    },
    {
        label: 'Bentley',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/bentley-gebrauchtwagen/',
        },
    },
    {
        label: 'BMW',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/bmw-gebrauchtwagen/',
        },
    },
    {
        label: 'British Leyland',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/british-leyland-gebrauchtwagen/',
        },
    },
    {
        label: 'Buick',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/buick-gebrauchtwagen/',
        },
    },
    {
        label: 'Cadillac',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/cadillac-gebrauchtwagen/',
        },
    },
    {
        label: 'Casalini',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/casalini-gebrauchtwagen/',
        },
    },
    {
        label: 'Caterham',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/caterham-gebrauchtwagen/',
        },
    },
    {
        label: 'Chevrolet',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/chevrolet-gebrauchtwagen/',
        },
    },
    {
        label: 'Chevrolet / Daewoo',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/chevrolet-daewoo-gebrauchtwagen/',
        },
    },
    {
        label: 'Chrysler',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/chrysler-gebrauchtwagen/',
        },
    },
    {
        label: 'Citroën',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/citroen-gebrauchtwagen/',
        },
    },
    {
        label: 'Corvette',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/corvette-gebrauchtwagen/',
        },
    },
    {
        label: 'Cupra',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/cupra-gebrauchtwagen/',
        },
    },
    {
        label: 'Dacia',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/dacia-gebrauchtwagen/',
        },
    },
    {
        label: 'Daihatsu',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/daihatsu-gebrauchtwagen/',
        },
    },
    {
        label: 'Dodge',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/dodge-gebrauchtwagen/',
        },
    },
    {
        label: 'DS Automobiles',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/ds-automobiles-gebrauchtwagen/',
        },
    },
    {
        label: 'Ferrari',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/ferrari-gebrauchtwagen/',
        },
    },
    {
        label: 'Fiat',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/fiat-gebrauchtwagen/',
        },
    },
    {
        label: 'Ford',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/ford-gebrauchtwagen/',
        },
    },
    {
        label: 'Graf Carello',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/graf-carello-gebrauchtwagen/',
        },
    },
    {
        label: 'Honda',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/honda-gebrauchtwagen/',
        },
    },
    {
        label: 'Hummer',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/hummer-gebrauchtwagen/',
        },
    },
    {
        label: 'Hyundai',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/hyundai-gebrauchtwagen/',
        },
    },
    {
        label: 'Infiniti',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/infiniti-gebrauchtwagen/',
        },
    },
    {
        label: 'Isuzu',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/isuzu-gebrauchtwagen/',
        },
    },
    {
        label: 'IVECO',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/iveco-gebrauchtwagen/',
        },
    },
    {
        label: 'JAC',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/jac-gebrauchtwagen/',
        },
    },
    {
        label: 'Jaguar',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/jaguar-gebrauchtwagen/',
        },
    },
    {
        label: 'Jeep',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/jeep-gebrauchtwagen/',
        },
    },
    {
        label: 'KIA',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/kia-gebrauchtwagen/',
        },
    },
    {
        label: 'KTM',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/ktm-gebrauchtwagen/',
        },
    },
    {
        label: 'Lada',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/lada-gebrauchtwagen/',
        },
    },
    {
        label: 'Lamborghini',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/lamborghini-gebrauchtwagen/',
        },
    },
    {
        label: 'Lancia',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/lancia-gebrauchtwagen/',
        },
    },
    {
        label: 'Land Rover',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/land-rover-gebrauchtwagen/',
        },
    },
    {
        label: 'Lexus',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/lexus-gebrauchtwagen/',
        },
    },
    {
        label: 'Ligier',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/ligier-gebrauchtwagen/',
        },
    },
    {
        label: 'Lincoln',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/lincoln-gebrauchtwagen/',
        },
    },
    {
        label: 'Lotus',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/lotus-gebrauchtwagen/',
        },
    },
    {
        label: 'Mahindra',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/mahindra-gebrauchtwagen/',
        },
    },
    {
        label: 'Maserati',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/maserati-gebrauchtwagen/',
        },
    },
    {
        label: 'Maybach',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/maybach-gebrauchtwagen/',
        },
    },
    {
        label: 'Mazda',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/mazda-gebrauchtwagen/',
        },
    },
    {
        label: 'McLaren',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/mclaren-gebrauchtwagen/',
        },
    },
    {
        label: 'Mercedes-Benz',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/mercedes-gebrauchtwagen/',
        },
    },
    {
        label: 'Mercury',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/mercury-gebrauchtwagen/',
        },
    },
    {
        label: 'MG',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/mg-gebrauchtwagen/',
        },
    },
    {
        label: 'Microcar',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/microcar-gebrauchtwagen/',
        },
    },
    {
        label: 'MINI',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/mini-gebrauchtwagen/',
        },
    },
    {
        label: 'Mitsubishi',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/mitsubishi-gebrauchtwagen/',
        },
    },
    {
        label: 'Morgan',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/morgan-gebrauchtwagen/',
        },
    },
    {
        label: 'Nissan',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/nissan-gebrauchtwagen/',
        },
    },
    {
        label: 'Opel',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/opel-gebrauchtwagen/',
        },
    },
    {
        label: 'Peugeot',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/peugeot-gebrauchtwagen/',
        },
    },
    {
        label: 'Pontiac',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/pontiac-gebrauchtwagen/',
        },
    },
    {
        label: 'Porsche',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/porsche-gebrauchtwagen/',
        },
    },
    {
        label: 'Puch',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/puch-gebrauchtwagen/',
        },
    },
    {
        label: 'Renault',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/renault-gebrauchtwagen/',
        },
    },
    {
        label: 'Rolls-Royce',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/rolls-royce-gebrauchtwagen/',
        },
    },
    {
        label: 'Rover',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/rover-gebrauchtwagen/',
        },
    },
    {
        label: 'Saab',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/saab-gebrauchtwagen/',
        },
    },
    {
        label: 'Seat',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/seat-gebrauchtwagen/',
        },
    },
    {
        label: 'Skoda',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/skoda-gebrauchtwagen/',
        },
    },
    {
        label: 'Smart',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/smart-gebrauchtwagen/',
        },
    },
    {
        label: 'SsangYong',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/ssangyong-gebrauchtwagen/',
        },
    },
    {
        label: 'Subaru',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/subaru-gebrauchtwagen/',
        },
    },
    {
        label: 'Suzuki',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/suzuki-gebrauchtwagen/',
        },
    },
    {
        label: 'Talbot',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/talbot-gebrauchtwagen/',
        },
    },
    {
        label: 'Tata',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/tata-gebrauchtwagen/',
        },
    },
    {
        label: 'Tesla',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/tesla-gebrauchtwagen/',
        },
    },
    {
        label: 'Think',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/think-gebrauchtwagen/',
        },
    },
    {
        label: 'Toyota',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/toyota-gebrauchtwagen/',
        },
    },
    {
        label: 'Trabant',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/trabant-gebrauchtwagen/',
        },
    },
    {
        label: 'Triumph',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/triumph-gebrauchtwagen/',
        },
    },
    {
        label: 'Volvo',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/volvo-gebrauchtwagen/',
        },
    },
    {
        label: 'VW',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/vw-gebrauchtwagen/',
        },
    },
    {
        label: 'Wiesmann',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/wiesmann-gebrauchtwagen/',
        },
    },
    {
        label: 'ZhiDou',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/zhidou-gebrauchtwagen/',
        },
    },
    {
        label: 'Sonstige',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/auto/sonstige-gebrauchtwagen/',
        },
    },
]
