import { LinkVomitPossibleValue } from '@bbx/search-journey/sub-domains/search/components/common/common/PossibleValueLinkVomit/PossibleValueLinkVomit'

export const truckMakes: LinkVomitPossibleValue[] = [
    {
        label: 'APE',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/ape/',
        },
    },
    {
        label: 'Blyss',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/blyss/',
        },
    },
    {
        label: 'BOMAG',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/bomag/',
        },
    },
    {
        label: 'CASE',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/case/',
        },
    },
    {
        label: 'Caterpillar',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/caterpillar/',
        },
    },
    {
        label: 'Chevrolet',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/chevrolet/',
        },
    },
    {
        label: 'Citroën',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/citroen/',
        },
    },
    {
        label: 'Dacia',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/dacia/',
        },
    },
    {
        label: 'Daewoo',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/daewoo/',
        },
    },
    {
        label: 'DAF',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/daf/',
        },
    },
    {
        label: 'DEUTZ-FAHR',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/deutz-fahr/',
        },
    },
    {
        label: 'DFSK',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/dfsk/',
        },
    },
    {
        label: 'Dodge',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/dodge/',
        },
    },
    {
        label: 'Faun',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/faun/',
        },
    },
    {
        label: 'Fendt',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/fendt/',
        },
    },
    {
        label: 'Fiat',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/fiat/',
        },
    },
    {
        label: 'Ford',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/ford/',
        },
    },
    {
        label: 'Hummer',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/hummer/',
        },
    },
    {
        label: 'Hyundai',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/hyundai/',
        },
    },
    {
        label: 'Irisbus',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/irisbus/',
        },
    },
    {
        label: 'Iseki',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/iseki/',
        },
    },
    {
        label: 'Isuzu',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/isuzu/',
        },
    },
    {
        label: 'IVECO',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/iveco/',
        },
    },
    {
        label: 'JCB',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/jcb/',
        },
    },
    {
        label: 'Jeep',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/jeep/',
        },
    },
    {
        label: 'John Deere',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/john-deere/',
        },
    },
    {
        label: 'Jungheinrich',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/jungheinrich/',
        },
    },
    {
        label: 'KIA',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/kia/',
        },
    },
    {
        label: 'Krone',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/krone/',
        },
    },
    {
        label: 'Kubota',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/kubota/',
        },
    },
    {
        label: 'Kögel',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/koegel/',
        },
    },
    {
        label: 'Land Rover',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/land-rover/',
        },
    },
    {
        label: 'Liebherr',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/liebherr/',
        },
    },
    {
        label: 'Linde',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/linde/',
        },
    },
    {
        label: 'Lindner',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/lindner/',
        },
    },
    {
        label: 'MAN',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/man/',
        },
    },
    {
        label: 'Massey Ferguson',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/massey-ferguson/',
        },
    },
    {
        label: 'Mazda',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/mazda/',
        },
    },
    {
        label: 'Mercedes-Benz',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/mercedes-benz/',
        },
    },
    {
        label: 'Mitsubishi',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/mitsubishi/',
        },
    },
    {
        label: 'Neoplan',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/neoplan/',
        },
    },
    {
        label: 'New Holland',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/new-holland/',
        },
    },
    {
        label: 'Nissan',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/nissan/',
        },
    },
    {
        label: 'Opel',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/opel/',
        },
    },
    {
        label: 'Peugeot',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/peugeot/',
        },
    },
    {
        label: 'Piaggio',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/piaggio/',
        },
    },
    {
        label: 'Puch',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/puch/',
        },
    },
    {
        label: 'Renault',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/renault/',
        },
    },
    {
        label: 'SAME',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/same/',
        },
    },
    {
        label: 'Scania',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/scania/',
        },
    },
    {
        label: 'Schmitz',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/schmitz/',
        },
    },
    {
        label: 'Schwarzmüller',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/schwarzmueller/',
        },
    },
    {
        label: 'Seat',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/seat/',
        },
    },
    {
        label: 'Skoda',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/skoda/',
        },
    },
    {
        label: 'Solis',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/solis/',
        },
    },
    {
        label: 'SsangYong',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/ssangyong/',
        },
    },
    {
        label: 'Steyr',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/steyr/',
        },
    },
    {
        label: 'Still',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/still/',
        },
    },
    {
        label: 'Suzuki',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/suzuki/',
        },
    },
    {
        label: 'Takeuchi',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/takeuchi/',
        },
    },
    {
        label: 'Toyota',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/toyota/',
        },
    },
    {
        label: 'Unimog',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/unimog/',
        },
    },
    {
        label: 'Volvo',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/volvo/',
        },
    },
    {
        label: 'VW',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/vw/',
        },
    },
    {
        label: 'Sonstige',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/nutzfahrzeuge/sonstige/',
        },
    },
]
