/* eslint-disable max-lines */
import { LinkVomitPossibleValue } from '@bbx/search-journey/sub-domains/search/components/common/common/PossibleValueLinkVomit/PossibleValueLinkVomit'

export const motorcycleMakes: LinkVomitPossibleValue[] = [
    {
        label: 'Access',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/access-motorrad/',
        },
    },
    {
        label: 'Adly',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/adly-motorrad/',
        },
    },
    {
        label: 'Aeon',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/aeon-motorrad/',
        },
    },
    {
        label: 'Aprilia',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/aprilia-motorrad/',
        },
    },
    {
        label: 'Arctic Cat',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/arctic-cat-motorrad/',
        },
    },
    {
        label: 'Atala',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/atala-motorrad/',
        },
    },
    {
        label: 'Bajaj',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/bajaj-motorrad/',
        },
    },
    {
        label: 'Beeline',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/beeline-motorrad/',
        },
    },
    {
        label: 'Benelli',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/benelli-motorrad/',
        },
    },
    {
        label: 'Beta',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/beta-motorrad/',
        },
    },
    {
        label: 'Bimota',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/bimota-motorrad/',
        },
    },
    {
        label: 'BMW',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/bmw-motorrad/',
        },
    },
    {
        label: 'Bombardier',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/bombardier-motorrad/',
        },
    },
    {
        label: 'Boom Trikes',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/boom-trikes-motorrad/',
        },
    },
    {
        label: 'Brixton',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/brixton-motorrad/',
        },
    },
    {
        label: 'BSA',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/bsa-motorrad/',
        },
    },
    {
        label: 'Buell',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/buell-motorrad/',
        },
    },
    {
        label: 'Bultaco',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/bultaco-motorrad/',
        },
    },
    {
        label: 'Cagiva',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/cagiva-motorrad/',
        },
    },
    {
        label: 'Can-Am',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/can-am-motorrad/',
        },
    },
    {
        label: 'CF-Moto',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/cf-moto-motorrad/',
        },
    },
    {
        label: 'Condor',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/condor-motorrad/',
        },
    },
    {
        label: 'CPI',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/cpi-motorrad/',
        },
    },
    {
        label: 'Daelim',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/daelim-motorrad/',
        },
    },
    {
        label: 'Derbi',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/derbi-motorrad/',
        },
    },
    {
        label: 'Dinli',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/dinli-motorrad/',
        },
    },
    {
        label: 'DKW',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/dkw-motorrad/',
        },
    },
    {
        label: 'Doohan',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/doohan-motorrad/',
        },
    },
    {
        label: 'DSR',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/dsr-motorrad/',
        },
    },
    {
        label: 'Ducati',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/ducati-motorrad/',
        },
    },
    {
        label: 'E-TON',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/e-ton-motorrad/',
        },
    },
    {
        label: 'Electric Motion',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/electric-motion-motorrad/',
        },
    },
    {
        label: 'Energica',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/energica-motorrad/',
        },
    },
    {
        label: 'Explorer',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/explorer-motorrad/',
        },
    },
    {
        label: 'Fantic',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/fantic-motorrad/',
        },
    },
    {
        label: 'Gas Gas',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/gas-gas-motorrad/',
        },
    },
    {
        label: 'Generic',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/generic-motorrad/',
        },
    },
    {
        label: 'Gilera',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/gilera-motorrad/',
        },
    },
    {
        label: 'GOES',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/goes-motorrad/',
        },
    },
    {
        label: 'Harley-Davidson',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/harley-davidson-motorrad/',
        },
    },
    {
        label: 'Hercules',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/hercules-motorrad/',
        },
    },
    {
        label: 'HiSun',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/hisun-motorrad/',
        },
    },
    {
        label: 'HMW',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/hmw-motorrad/',
        },
    },
    {
        label: 'Honda',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/honda-motorrad/',
        },
    },
    {
        label: 'Horex',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/horex-motorrad/',
        },
    },
    {
        label: 'Husaberg',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/husaberg-motorrad/',
        },
    },
    {
        label: 'Husqvarna',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/husqvarna-motorrad/',
        },
    },
    {
        label: 'Hyosung',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/hyosung-motorrad/',
        },
    },
    {
        label: 'Indian',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/indian-motorrad/',
        },
    },
    {
        label: 'IO',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/io-motorrad/',
        },
    },
    {
        label: 'Iron Horse',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/iron-horse-motorrad/',
        },
    },
    {
        label: 'Italjet',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/italjet-motorrad/',
        },
    },
    {
        label: 'Jawa',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/jawa-motorrad/',
        },
    },
    {
        label: 'Jotagas',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/jotagas-motorrad/',
        },
    },
    {
        label: 'Kawasaki',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/kawasaki-motorrad/',
        },
    },
    {
        label: 'Kayo',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/kayo-motorrad/',
        },
    },
    {
        label: 'Keeway',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/keeway-motorrad/',
        },
    },
    {
        label: 'KSR Moto',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/ksr-moto-motorrad/',
        },
    },
    {
        label: 'KTM',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/ktm-motorrad/',
        },
    },
    {
        label: 'Kumpan',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/kumpan-motorrad/',
        },
    },
    {
        label: 'KXD',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/kxd-motorrad/',
        },
    },
    {
        label: 'Kymco',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/kymco-motorrad/',
        },
    },
    {
        label: 'Lambretta',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/lambretta-motorrad/',
        },
    },
    {
        label: 'Linhai',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/linhai-motorrad/',
        },
    },
    {
        label: 'LML',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/lml-motorrad/',
        },
    },
    {
        label: 'Lohner',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/lohner-motorrad/',
        },
    },
    {
        label: 'Lynx',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/lynx-motorrad/',
        },
    },
    {
        label: 'Malaguti',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/malaguti-motorrad/',
        },
    },
    {
        label: 'Mash',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/mash-motorrad/',
        },
    },
    {
        label: 'Mondial',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/mondial-motorrad/',
        },
    },
    {
        label: 'Montesa',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/montesa-motorrad/',
        },
    },
    {
        label: 'Moto Guzzi',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/moto-guzzi-motorrad/',
        },
    },
    {
        label: 'Moto Morini',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/moto-morini-motorrad/',
        },
    },
    {
        label: 'Motobi',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/motobi-motorrad/',
        },
    },
    {
        label: 'Motorhispania',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/motorhispania-motorrad/',
        },
    },
    {
        label: 'MV Agusta',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/mv-agusta-motorrad/',
        },
    },
    {
        label: 'MZ',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/mz-motorrad/',
        },
    },
    {
        label: 'Nitro Motors',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/nitro-motors-motorrad/',
        },
    },
    {
        label: 'Niu',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/niu-motorrad/',
        },
    },
    {
        label: 'Norton',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/norton-motorrad/',
        },
    },
    {
        label: 'NSU',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/nsu-motorrad/',
        },
    },
    {
        label: 'Odes',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/odes-motorrad/',
        },
    },
    {
        label: 'Online',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/online-motorrad/',
        },
    },
    {
        label: 'Oset',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/oset-motorrad/',
        },
    },
    {
        label: 'Panthera',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/panthera-motorrad/',
        },
    },
    {
        label: 'Peugeot',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/peugeot-motorrad/',
        },
    },
    {
        label: 'PGO',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/pgo-motorrad/',
        },
    },
    {
        label: 'Piaggio',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/piaggio-motorrad/',
        },
    },
    {
        label: 'Polaris',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/polaris-motorrad/',
        },
    },
    {
        label: 'Puch',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/puch-motorrad/',
        },
    },
    {
        label: 'Quadix',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/quadix-motorrad/',
        },
    },
    {
        label: 'Rewaco',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/rewaco-motorrad/',
        },
    },
    {
        label: 'Rex',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/rex-motorrad/',
        },
    },
    {
        label: 'Rieju',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/rieju-motorrad/',
        },
    },
    {
        label: 'Rivero',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/rivero-motorrad/',
        },
    },
    {
        label: 'Royal Enfield',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/royal-enfield-motorrad/',
        },
    },
    {
        label: 'S-Moto',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/s-moto-motorrad/',
        },
    },
    {
        label: 'Sachs',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/sachs-motorrad/',
        },
    },
    {
        label: 'Scomadi',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/scomadi-motorrad/',
        },
    },
    {
        label: 'Sherco',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/sherco-motorrad/',
        },
    },
    {
        label: 'Simson',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/simson-motorrad/',
        },
    },
    {
        label: 'Skyteam',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/skyteam-motorrad/',
        },
    },
    {
        label: 'Soco',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/soco-motorrad/',
        },
    },
    {
        label: 'Sur-Ron',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/sur-ron-motorrad/',
        },
    },
    {
        label: 'Suzuki',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/suzuki-motorrad/',
        },
    },
    {
        label: 'Sym',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/sym-motorrad/',
        },
    },
    {
        label: 'Tauris',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/tauris-motorrad/',
        },
    },
    {
        label: 'TGB',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/tgb-motorrad/',
        },
    },
    {
        label: 'Torrot',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/torrot-motorrad/',
        },
    },
    {
        label: 'Triumph',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/triumph-motorrad/',
        },
    },
    {
        label: 'UM',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/um-motorrad/',
        },
    },
    {
        label: 'Velosolex',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/velosolex-motorrad/',
        },
    },
    {
        label: 'Vertigo',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/vertigo-motorrad/',
        },
    },
    {
        label: 'Vespa',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/vespa-motorrad/',
        },
    },
    {
        label: 'Victory',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/victory-motorrad/',
        },
    },
    {
        label: 'X-Moto',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/x-moto-motorrad/',
        },
    },
    {
        label: 'Yamaha',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/yamaha-motorrad/',
        },
    },
    {
        label: 'YCF',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/ycf-motorrad/',
        },
    },
    {
        label: 'Zero',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/zero-motorrad/',
        },
    },
    {
        label: 'Znen',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/znen-motorrad/',
        },
    },
    {
        label: 'Zündapp',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/zuendapp-motorrad/',
        },
    },
    {
        label: 'Sonstige',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/motorrad/sonstige-motorrad/',
        },
    },
]
