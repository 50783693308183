import { SearchId } from '@bbx/common/api/dtos/search-id'
import { carMakes } from '@bbx/search-journey/sub-domains/search/api/static/car-makes'
import { motorcycleMakes } from '@bbx/search-journey/sub-domains/search/api/static/motorcycle-makes'
import { caravanMakes } from '@bbx/search-journey/sub-domains/search/api/static/caravan-makes'
import { truckMakes } from '@bbx/search-journey/sub-domains/search/api/static/truck-makes'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { getFlatPossibleValues, getNavigatorById } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

export const getMakePossibleValues = (searchId: SearchId) => {
    switch (searchId) {
        case SearchId.Cars:
            return carMakes
        case SearchId.Motorcycles:
            return motorcycleMakes
        case SearchId.Caravans:
            return caravanMakes
        case SearchId.Trucks:
            return truckMakes
        case SearchId.DealerCars:
        case SearchId.DealerMotorcycles:
        case SearchId.DealerCaravans:
        case SearchId.DealerTrucks:
        default:
            return []
    }
}

export const getMakePossibleValuesFromSearchResult = (searchResult: SearchResult) => {
    const navigator = getNavigatorById(searchResult, 'make')
    return getFlatPossibleValues(navigator).filter((possibleValue) => possibleValue.hits > 0) ?? []
}
