import React, { FunctionComponent } from 'react'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import {
    ModalNavigator,
    ModalNavigatorProps,
} from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/ModalNavigator'
import { WidthProps } from '@wh-components/system/layout'

export const MakeModalNavigator: FunctionComponent<
    NavigatorProps & ModalNavigatorProps & Partial<Pick<WidthProps, 'width' | 'maxWidth'>>
> = ({
    navigator,
    onSearch,
    'aria-labelledby': ariaLabelledBy,
    showSelectedValues,
    width,
    disabled = false,
    maxWidth,
    selectedValueInColumns,
    taggingData,
}) => (
    <ModalNavigator
        navigator={navigator}
        onSearch={onSearch}
        aria-labelledby={ariaLabelledBy}
        id="make"
        modalHeader="Marken"
        showSelectedValues={showSelectedValues}
        width={width}
        maxWidth={maxWidth}
        disabled={disabled}
        selectedValueInColumns={selectedValueInColumns}
        taggingEvent="search_result_list_show_all_makes"
        taggingData={taggingData}
    />
)
