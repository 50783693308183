import { LinkVomitPossibleValue } from '@bbx/search-journey/sub-domains/search/components/common/common/PossibleValueLinkVomit/PossibleValueLinkVomit'

export const caravanMakes: LinkVomitPossibleValue[] = [
    {
        label: 'Adria',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/adria/',
        },
    },
    {
        label: 'Ahorn',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/ahorn/',
        },
    },
    {
        label: 'Bravia',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/bravia/',
        },
    },
    {
        label: 'Bürstner',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/buerstner/',
        },
    },
    {
        label: 'Carado',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/carado/',
        },
    },
    {
        label: 'Caravelair',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/caravelair/',
        },
    },
    {
        label: 'Carthago',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/carthago/',
        },
    },
    {
        label: 'Challenger',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/challenger/',
        },
    },
    {
        label: 'Chausson',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/chausson/',
        },
    },
    {
        label: 'Concorde',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/concorde/',
        },
    },
    {
        label: 'Dethleffs',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/dethleffs/',
        },
    },
    {
        label: 'Dreamer',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/dreamer/',
        },
    },
    {
        label: 'Eifelland',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/eifelland/',
        },
    },
    {
        label: 'Elnagh',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/elnagh/',
        },
    },
    {
        label: 'Eriba',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/eriba/',
        },
    },
    {
        label: 'Etrusco',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/etrusco/',
        },
    },
    {
        label: 'Eura Mobil',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/eura-mobil/',
        },
    },
    {
        label: 'Fendt',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/fendt/',
        },
    },
    {
        label: 'Fiat',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/fiat/',
        },
    },
    {
        label: 'Ford',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/ford/',
        },
    },
    {
        label: 'Free Living',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/free-living/',
        },
    },
    {
        label: 'General Motors',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/general-motors/',
        },
    },
    {
        label: 'GlobeCar',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/globecar/',
        },
    },
    {
        label: 'Granduca',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/granduca/',
        },
    },
    {
        label: 'Hobby',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/hobby/',
        },
    },
    {
        label: 'Holiday Rambler',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/holiday-rambler/',
        },
    },
    {
        label: 'Hymer',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/hymer/',
        },
    },
    {
        label: 'IVECO',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/iveco/',
        },
    },
    {
        label: 'KABE',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/kabe/',
        },
    },
    {
        label: 'Kip',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/kip/',
        },
    },
    {
        label: 'Knaus',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/knaus/',
        },
    },
    {
        label: 'La Strada',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/la-strada/',
        },
    },
    {
        label: 'Laika',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/laika/',
        },
    },
    {
        label: 'LMC/Münsterland',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/lmc-muensterland/',
        },
    },
    {
        label: 'Malibu',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/malibu/',
        },
    },
    {
        label: 'Mercedes-Benz',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/mercedes-benz/',
        },
    },
    {
        label: 'Miller',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/miller/',
        },
    },
    {
        label: 'Morelo',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/morelo/',
        },
    },
    {
        label: 'Niesman-Bischoff',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/niesman-bischoff/',
        },
    },
    {
        label: 'Nordstar',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/nordstar/',
        },
    },
    {
        label: 'Opel',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/opel/',
        },
    },
    {
        label: 'P.L.A.',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/p-l-a-/',
        },
    },
    {
        label: 'Peugeot',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/peugeot/',
        },
    },
    {
        label: 'Pössl',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/poessl/',
        },
    },
    {
        label: 'Rapido',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/rapido/',
        },
    },
    {
        label: 'Renault',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/renault/',
        },
    },
    {
        label: 'Rimor',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/rimor/',
        },
    },
    {
        label: 'RMB',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/rmb/',
        },
    },
    {
        label: 'Roller Team',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/roller-team/',
        },
    },
    {
        label: 'Selbstbau',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/selbstbau/',
        },
    },
    {
        label: 'Sterckeman',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/sterckeman/',
        },
    },
    {
        label: 'Sun Living',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/sun-living/',
        },
    },
    {
        label: 'Sunlight',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/sunlight/',
        },
    },
    {
        label: 'T.E. Caravan',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/t-e-caravan/',
        },
    },
    {
        label: 'Tabbert',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/tabbert/',
        },
    },
    {
        label: 'Tourne',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/tourne/',
        },
    },
    {
        label: 'VANtourer',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/vantourer/',
        },
    },
    {
        label: 'Vario',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/vario/',
        },
    },
    {
        label: 'VW',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/vw/',
        },
    },
    {
        label: 'Weinsberg',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/weinsberg/',
        },
    },
    {
        label: 'Westfalia',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/westfalia/',
        },
    },
    {
        label: 'Winnebago',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/winnebago/',
        },
    },
    {
        label: 'Sonstige',
        webLink: {
            relativePath: '/iad/gebrauchtwagen/wohnwagen-wohnmobile/sonstige/',
        },
    },
]
