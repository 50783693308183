import React, { FunctionComponent, useCallback, useEffect, useState, useRef } from 'react'
import SearchIcon from '@wh-components/icons/Search'
import ResetIcon from '@wh-components/icons/Reset'
import { Input } from '@wh-components/core/FormElements/Input/Input'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import styled, { DataAttributes, css } from 'styled-components'
import { testIdAttribute, TestProps } from '@wh-components/core/common'
import { useDebouncedCallback } from 'use-debounce'
import { useScrollNavigatorInView } from '@bbx/common/hooks/useScrollNavigatorInView'

const KeywordForm = styled.form.attrs<TestProps & DataAttributes>(testIdAttribute)`
    width: 100%;
`

export const KeywordNavigator: FunctionComponent<
    NavigatorProps & { showLabel?: boolean; placeholder?: string; abortRequest: () => void }
> = ({ navigator, onSearch, taggingData, showLabel = true, placeholder, abortRequest }) => {
    const hasSelectedValue = navigator.selectedValues.length > 0

    const getSelectedValue = useCallback(() => {
        return hasSelectedValue ? navigator.selectedValues[0].label : ''
    }, [navigator.selectedValues, hasSelectedValue])

    const selectedValue = getSelectedValue()
    const [value, setValue] = useState<string>(selectedValue)

    const inputRef = useRef<HTMLInputElement>(null)
    const { scrollNavigatorInView } = useScrollNavigatorInView(inputRef)

    const onSubmit = async (values: { keyword: string }) => {
        const additionalParams: Record<string, string> = {}
        const paramName = navigator.urlConstructionInformation.urlParams[0].urlParameterName
        additionalParams[paramName] = values.keyword
        await onSearch(navigator.urlConstructionInformation.baseUrl, additionalParams)
        callActionEvent('search_result_list_keyword_search', taggingData)
    }

    const [isHighlightedText, setIsHighlightedText] = useState<boolean>(hasSelectedValue && value.length > 0)

    const resetValue = async () => {
        setValue('')
        if (hasSelectedValue && navigator.selectedValues[0].resetLink) {
            await onSearch(navigator.selectedValues[0].resetLink)
        }
    }

    useEffect(() => {
        setValue(selectedValue)
    }, [selectedValue])

    const debouncedSubmit = useDebouncedCallback(() => {
        onSubmit({ keyword: value })
    }, 350)

    const handleChangeAndSubmit = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setValue(e.target.value)
        abortRequest?.()
        debouncedSubmit()
    }

    return (
        <KeywordForm
            id="suchbegriff-form"
            testId="suchbegriff-form"
            onSubmit={(e) => {
                e.preventDefault()
                inputRef.current?.blur()
            }}
            autoComplete="off"
        >
            <Input
                id="suchbegriff-input"
                placeholder={placeholder ?? navigator.metadata?.placeholder ?? undefined}
                label={showLabel ? navigator.label : undefined}
                aria-label={!showLabel ? navigator.label : undefined}
                type="text"
                name="keyword"
                size={{ phone: 'large', tablet: 'medium' }}
                width="100%"
                ref={inputRef}
                css={css`
                    input {
                        font-weight: ${isHighlightedText ? (p) => p.theme.fontWeights.bold : 'inherit'};
                        color: ${isHighlightedText ? (p) => p.theme.colors.palette.primary.main : 'inherit'};

                        &::placeholder {
                            font-weight: ${(p) => p.theme.fontWeights.regular};
                        }
                    }
                `}
                value={value}
                onChange={handleChangeAndSubmit}
                onFocus={() => {
                    setIsHighlightedText(false)
                    scrollNavigatorInView()
                }}
                onBlur={() => {
                    setIsHighlightedText(true)
                }}
                testId="suchbegriff-input"
                Icon={value.length ? ResetIcon : SearchIcon}
                onIconClick={value.length ? resetValue : undefined}
                iconTestId="suchbegriff-submit"
                iconAriaLabel={value.length ? 'Zurücksetzen' : 'Suche'}
                // we'll ignore the parent aria-labelledby because the group of the navigator has a name that is irrelevant for this navigator
                // aria-labelledby={ariaLabelledBy}
            />
        </KeywordForm>
    )
}
